

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translate(-50%, -55%);
  }
  to {
      opacity: 1;
      transform: translate(-50%, -50%);
  }
}
.over-lay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}
.over-lay.active {
  display: block;
}

.otp-input-container {
  margin: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
}

.otp-input {
  margin: 0.5rem;
  padding: 0.5rem;
  max-width: 2rem;
  text-align: center;
}

.otp-error {
  border-radius: 4px;
  border: 0.125rem solid red;
  background-color: white;
}

.opt-resend-btn {
  color: #0a00ff;
  background-color: inherit;
  padding: 0;
  margin: 0;
  border-style: none;
  text-decoration: underline;
}
/* custom drop down  start here */
.custom-select {
  position: relative;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: #eee;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #616161;
  padding: 12px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

svg.drop-down-arrow {
  position: absolute;
  right: 12px;
  top: 12px;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: #fff;
  /* top: 100%; */
  left: 0;
  right: 0;
  z-index: 99;
  border: 1px solid black;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgb(155 149 149 / 10%);
  color: black;
  scale: 1.02;
  margin: 0 6px;
}

/* custom drop down  end here */
/* stepper style  start here */

.stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  .step {
    display: table-cell;
    position: relative;
    padding: 1.5rem;
    z-index: 2;
    width: 25%;
    &:last-child .step-bar-left,
    &:last-child .step-bar-right {
      display: none;
    }

    .step-circle {
      width: 2rem;
      height: 2rem;
      margin: 0 auto;
      border-radius: 50%;
      text-align: center;
      line-height: 1.75rem;
      font-size: 1rem;
      font-weight: 600;
      z-index: 2;
      border: 2px solid black;
    }

    &.done {
      .step-circle {
        background-color: black;
        border: 2px solid black;
        color: #ffffff;

        &:before {
          font-family: "FontAwesome";
          font-weight: 100;
          content: "\f00c";
        }
        .span {
          display: none;
        }
      }
      .step-title {
        color: #102a43;
      }
    }

    &.editing {
      .step-circle {
        background: black;
        border-color: black;
        color: #ffffff;

        display: flex;
        align-items: center;
        justify-content: center;
      }
      .step-title {
        color: #212121;
        /* text-decoration: underline; */
      }
    }

    .step-title {
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 600;
    }
    .step-title,
    .step-optional {
      text-align: center;
      color: #829ab1;
    }
    .step-optional {
      font-size: 0.75rem;
      font-style: italic;
      color: #9fb3c8;
    }

    .step-bar-left,
    .step-bar-right {
      position: absolute;
      top: calc(2rem + 5px);
      /* height: 5px; */
      background-color: #d9e2ec;
      border: solid #d9e2ec;
      border-width: 1px 0;
    }
    .step-bar-left {
      width: calc(100% - 4rem);
      left: 50%;
      margin: 0 1.9rem;
      z-index: -1;
    }
    .step-bar-right {
      width: 0;
      left: 50%;
      margin: 0 1.9rem;
      z-index: -1;
      transition: width 500ms ease-in-out;
    }
    &.done .step-bar-right {
      background-color: black;
      border-color: black;
      z-index: 3;
      width: calc(100% - 4rem);
    }
  }
}

.signup-sucess-p {
  font-size: 16px;
}

.signup-sucess-p-btn {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  background: white;
  border-radius: var(--Rectangle-Radius, 100px);
  border: 2px solid #000;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  background-color: #000;
  color: #fff;
}

.p-select-container {
  /* border: 1px solid #ccc; */
  padding: 8px;
  cursor: pointer;
  background-color: #eeeeee;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.p-select-list-wrapper {
  border: 1px solid #ccc;
  position: absolute;
  z-index: 1;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  top: 50px;
}

.p-relative {
  position: relative;
}
.p-select-input {
  width: 100% !important;
  margin-top: 5px !important;
  border-radius: 10px !important;
  padding: 5px !important;
  border: none !important;
  outline: none !important;
}
.p-select-lists {
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
  /* border-bottom: 1px solid #adadad; */
}
.p-select-lists:hover {
  /* scale: 1.02; */
}

.acc-p-select-container {
  /* border: 1px solid #ccc; */
  /* padding: 8px 15px 8px 10px; */
  cursor: pointer;
  background-color: #eeeeee;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.acc-p-select-wrapper {
  border: 1px solid #ccc !important;
  position: absolute !important;
  border: none !important;
  z-index: 1 !important;
  width: 100% !important;
  max-height: 209px !important;
  overflow-y: auto !important;
}

.acc-p-select-input {
  width: "100%";
  margin-top: "5px";
  border-radius: "10px";
  padding: "5px";
  border: "none";
  outline: "none";
}

/* stepper style  end here */

/* css for goal details */

.goal-details-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 60px;
}
@media (max-width: 991px) {
  .goal-details-container {
    padding: 0 20px;
  }
}
.goal-details-container-sub {
  text-align: center;
  display: flex;
  width: 505px;
  max-width: 100%;
  flex-direction: column;
}
.goal-detial-img {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 284px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  border-radius: 29px;
}
.divii-3 {
  border-radius: var(--Rectangle-Radius, 100px);
  background-color: var(--surface-light-dark-light-7, #eee);
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  padding-right: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
@media (max-width: 991px) {
  .divii-3 {
    max-width: 100%;
    padding-right: 20px;
  }
}
.divii-4 {
  border-radius: var(--Rectangle-Radius, 100px);
  background-color: #212121;
  display: flex;
  width: 200px;
  height: 14px;
  flex-direction: column;
}
.divii-5 {
  justify-content: space-between;
  align-items: start;
  align-self: stretch;
  display: flex;
  margin-top: 20px;
  width: 100%;
  gap: 20px;
}
@media (max-width: 991px) {
  .divii-5 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.divii-6 {
  display: flex;
  gap: 5px;
}
.divii-7 {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  white-space: nowrap;
  font: 600 22px/30px Urbanist, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .divii-7 {
    white-space: initial;
  }
}
.divii-8 {
  color: var(--text-greyscale-700, #616161);
  leading-trim: both;
  text-edge: cap;
  align-self: start;
  margin-top: 7px;
  white-space: nowrap;
  font: 600 14px/20px Urbanist, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .divii-8 {
    white-space: initial;
  }
}
.divii-9 {
  justify-content: center;
  align-self: stretch;
  display: flex;
  gap: 5px;
}
.divii-10 {
  color: var(--text-greyscale-700, #616161);
  align-self: center;
  white-space: nowrap;
  margin: auto 0;
  font: 600 14px Urbanist, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .divii-10 {
    white-space: initial;
  }
}
.divii-11 {
  color: #000;
  font: 600 22px Urbanist, -apple-system, Roboto, Helvetica, sans-serif;
}
.goal-detial-img-2 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.divii-12 {
  color: var(--text-general-text-light, #212121);
  leading-trim: both;
  text-edge: cap;
  align-self: center;
  margin-top: 37px;
  white-space: nowrap;
  font: 600 22px Urbanist, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .divii-12 {
    white-space: initial;
  }
}
.divii-13 {
  text-decoration: none;
  cursor: pointer;
  color: #000;
  white-space: nowrap;
  justify-content: center;
  border-radius: var(--Rectangle-Radius, 100px);
  background-color: #c4c4c4;
  align-self: center;
  margin-top: 36px;
  width: 350px;
  max-width: 100%;
  padding: 11px 30px;
  font: 600 16px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .divii-13 {
    white-space: initial;
    padding: 0 20px;
  }
}
.divii-14 {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: var(--Rectangle-Radius, 100px);
  background-color: var(--surface-general-black, #000);
  align-self: center;
  margin-top: 20px;
  width: 350px;
  max-width: 100%;
  padding: 11px 60px;
  font: 600 16px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .divii-14 {
    white-space: initial;
    padding: 0 20px;
  }
}
