/* for mobile responsive */
@media (min-width: 768px) {
  .custom-toast-container .Toastify__toast {
    border-radius: 30px; /* Customize the border-radius here */
    /* Add more custom styles here */
  }
  
  .Toastify__close-button{
    align-self: center !important;
  }
}
@media (max-width: 768px) {
  /* toastStyles.css */


  .h87{
    height: 87vh;
  }
  .image-container {
    flex: 3 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    position: relative;
    display: none;
  }
  .empty-img-container {
    flex: 3 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f0f0f0; */
    position: relative;
    display: none;
  }
  .tab-btn {
    justify-content: space-evenly;
  }
  .right-aside {
    display: none;
  }

  .login-footer-page {
    width: 100vw;
    position: unset;
    margin-left: unset;
  }

  .login-footer-page > ul > li > a {
    color: rgb(255, 255, 255);
  }
  /* alok */
  .form-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: unset;
    background-color: transparent;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    height: 100%;
  }

  .modal-box {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: unset;
    background-color: transparent;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    height: 100%;
  }
  label {
    font-family: Poppins;
    font-size: 14px;
  }
  .form-brand-ct {
    background: white;
    padding: 20px 10px 20px 10px;
    border-radius: 8px;
  }
  .forget-password {
    color: #212121;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-decoration: none;
  }
  .form-brand {
    background: #fffffff0;
    padding: 20px 10px 20px 10px;
    border-radius: 8px;
  }
  .term-policy > input {
    width: 13px;
    height: 13px;
    accent-color: black;
  }
  .cstpx-10 {
    padding: 12.52px;
  }
  .footer-ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    padding: 12px;
    list-style: none;
    white-space: nowrap;
    background: #000;
  }
  .footer-container > ul > li > a {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
  }
  .footer-ul li {
    width: calc(35.33% - 10px);
    margin-bottom: 3px;
    padding: 0 5px;
  }
  /* .conta{
  display: none;
} */
  .main-content {
    margin: 0 0px;
    padding: 12px;
    /* background-color: #f2f2f2; */
    flex: 1 1;
    transition: margin 0.3s;
    margin-bottom: 7rem;
  }
  section.fighter-cart-item {
    white-space: nowrap;
  }
  #modal-container {
    border-radius: 20px !important;
    min-width: 0px !important;
    /* background-color: #fff; */
  }
  .amout-typography {
    color: var(--Text-General-Text-Light, #212121);
    text-align: right;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  .activity-nav {
    display: flex;
    padding-bottom: 20px;
    white-space: nowrap;
  }
  .c-est-card {
    margin-bottom: 14px;
  }
  .messanger .messages .message img {
    border-radius: 50%;
    margin-right: 15px;
    width: 45px;
    height: 45px;
    object-fit: cover;
    overflow: hidden;
  }
  .subscription-drop-container {
    position: absolute;
    right: 11px;
    top: 47px;
  }
  .t-data {
    color: var(--text-general-text-light, #212121);
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  .product-thumbnail {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 100px;
    overflow: hidden;
    border-radius: 9%;
    max-width: none;
  }
  .fighter-name {
    color: #000;
    letter-spacing: 0;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 0px;
    white-space: initial;
    font: 600 18px/24px Urbanist, -apple-system, Roboto, Helvetica, sans-serif;
  }
  .terms-checkbox-input {
    accent-color: black;
    align-self: flex-start;
    margin-top: 8px;
  }
  .terms-check .ms-3 {
    margin-left: 0rem !important;
  }
  .checkout-btn {
    width: 100%;
    border: 1px solid black;
    padding: 8px 2px 10px 0px;
    align-items: center;
    border-radius: var(--Rectangle-Radius, 100px);
    background: #000000;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
  }
  .checkout-btn:hover {
    width: 100%;
    background: #ffffff;
    color: #000000;
    transition: background-color 0.3s, color 0.3s;
  }
  a.link-text {
    color: #000;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-decoration: none;
  }
  .goal-activity-tab {
    display: inline-flex;
    color: #000;
    width: 140px;
    padding: 7px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #000;
    color: #000;
    text-align: center;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  .vmb-wrap {
    display: flex !important;
    flex-flow: wrap;
  }
  span.cost-n {
    color: #000;
    text-align: center;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-right: 8px;
  }
  .f-subs-thumbnail {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 80px;
    overflow: hidden;
    border-radius: 9%;
    max-width: 100%;
  }
  .next-pay {
    font-size: 12px;
  }
  .w-mb-80 {
    width: 80% !important;
  }
  .w-mb-20 {
    width: 20% !important;
  }
  .conta {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #fff;
    transition: left 0.3s ease;
  }
  .breadcrumb-icon {
    display: block;
    font-size: 20px;
  }
  .close-toggle {
    position: absolute;
    display: block;
    top: -4px;
    right: 0px;
    background: black;
    border: none;
    padding: 6px;
    cursor: pointer;
    color: #fff;
    border-radius: 0px 0px 0px 12px;
  }
  .d-mb-none {
    display: none;
  }
  .profile-details {
    color: var(--text-general-text-light, #212121);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  .profilelink-copy-btn {
    padding: 5px;
    margin-bottom: 2.35rem;
    cursor: pointer;
  }
  .d-mb-block {
    display: block;
  }
  .sec-head {
    display: flex;
    color: #000;
    font-family: Urbanist;
    font-size: 1.5rem;
    gap: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.875rem;
  }
  .f-mb-detail {
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 15px;
  }
  .conta.open {
    left: 0;
    top: 60px;
  }
  .w-40 {
    width: 40px;
  }
  .top-fl {
    justify-content: space-between;
    padding: 6px 10px 6px 10px;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  nav.side-nav {
    margin-top: 46px !important;
  }
  .topbar {
    position: sticky;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 9999;
  }
  .footer-container {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
    bottom: 0px;
    margin-top: 49vh;
  }
  .cl-reverse {
    flex-direction: column-reverse;
  }
  .pmb-10 {
    padding-top: 10px;
  }
  .mb-bt-g {
    display: grid !important;
  }
  .f-page-head h2 {
    font-family: Urbanist;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .f-page-head p {
    color: #616161;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }
  .d-mb-flex {
    display: flex !important;
    gap: 10px;
  }
  button.follow-button.fav-btn {
    font-size: 13px;
    padding: 5px 19px;
  }
  .category-btn.sub-btn-fonts {
    font-size: 13px;
    padding: 5px 19px;
  }
  .filter-goal .surprise-goal {
    display: flex;
    flex-direction: row;
    gap: 19px;
  }
  .category-btn {
    width: 7.5rem;
    height: 2rem;
  }
  .rightgoal.all-btn {
    font-size: 13px;
    padding: 5px 19px;
    width: 7.5rem;
    height: 2rem;
  }
  button.follow-profile-btn {
    width: 100px;
    font-size: 13px;
  }
  .fighter-image {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    max-width: 100%;
  }
  button.addGoalToCart {
    font-size: 13px;
    padding: 10px 20px;
  }
  button.addToCartCheckout {
    font-size: 13px;
    padding: 10px 20px;
  }
  .goal-detial-img {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 240px;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    border-radius: 29px;
  }
  .giftmodal-body {
    margin: 20px 20px;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .add-surprise-to-card-btn {
    width: 100% !important;
    color: black;
  }
  .save-social-btn {
    width: 100% !important;
  }
  .modal-content {
    text-align: center !important;
    padding: 1.563rem 1rem !important;
  }
  .add-message-modal-body {
    margin: 0px 0px;
  }
  .favorite-fighters-container {
    display: flex;
    flex-wrap: wrap;
  }
  .favorite-fighter {
    flex: 0 0 140px;
    margin: 10px;
    text-align: center;
    border-radius: 20px;
    border: 1px solid rgba(217, 217, 217, 0.85);
    background: #fff;
    text-decoration: none;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
  .fighter-img-container {
    width: 100%;
    height: 8.063rem;
    max-height: 100%;
  }
  .fav-fighter-name {
    padding: 4px 4px 4px 4px;
    color: #000;
    text-align: center;
    font-family: Urbanist;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  .goal-title {
    font-size: 13px;
  }
  .socal-links {
    margin: 0 0rem;
  }
  img.goal-preve-image {
    width: unset !important;
  }
}
/* mobile responsive end here */

/* for 90 percents screen view */
@media (min-width: 1300px) {
  .tab-btn {
    justify-content: space-evenly;
    /* padding: 0 28px 0px 28px; */
  }
}
/* for 80 %  screen view*/
@media (min-width: 1600px) {
  .celebrity-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto 24px 0px 14px;
  }
}
/* for 75 %  screen view*/
@media (min-width: 1700px) {
  .tab-btn {
    justify-content: space-evenly;
    padding: 0 28px 0px 28px;
  }
  .w-124 {
    width: 124px !important;
  }
  .mx-1-75 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
}
/* for 65 %  screen view*/
@media (min-width: 1367px) {
  .landing-head h1 {
    font-size: 2.3rem;
  }
}
@media (min-width: 1119px) {
  button.tab-btn-signin {
    padding: 10px 36px;
    font-size: 16px;
  }
  button.tab-btn-signup {
    padding: 10px 40px;
    font-size: 16px;
  }
}
@media (min-width: 1100px) {
  .login-footer-page {
    width: 62vw;
    margin-left: 5vw;
  }
}
.tab-btn {
  justify-content: space-around;
  justify-content: center;
  gap: 26px;
}
