.shimmerBG {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
}

.as-Shimer {
  animation-duration: 10s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #e6e6e6 5%, #cccccc 25%, #e6e6e6 35%);
  background-size: 1200px 100%;
}

/* shimmer css for fighter profile start */
/* shimmer css for search fighter start */

.spsh-title > div {
  height: 2.5rem;
  width: 10rem;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.spsh-searchInput {
  height: 2.5rem;
  margin-top: 1rem;
  border: 10px;
}

.spsh-list-details {
  width: 60%;
}
.spsh-list-detail {
  width: 100%;
  height: 2.5rem;
  border-radius: 10px;
}

.spsh-alpha-list {
  width: 3rem;
  height: inherit;
}

/* shimmer css for search fighter end */

.bannerShimmer {
  height: 8rem;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  position: relative;
}

.goal-shimmer {
  position: relative;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 6px;
  height: 350px;
  overflow: hidden;
  width: 90vw;

  .media {
    height: 170px;
  }

  .p-32 {
    padding: 32px;
  }

  .title-line {
    height: 24px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 20px;
  }

  .content-line {
    height: 8px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
  }

  .end {
    width: 40%;
  }
}

.user-profileshim {
  position: absolute;
  bottom: -36%;

  width: 8rem;
  height: 8rem;
  border-radius: 67px;
  transform: translateZ(1);
  -webkit-transform: translateZ(1);
  -moz-transform: translateZ(1);
  -ms-transform: translateZ(1);
  -o-transform: translateZ(1);
}

.socail-link-shimmer {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  margin: 0 0.5rem;
}

.data-join-shm {
  height: 2rem;
  width: 8rem;
  border-radius: 14px;
}

.fighterProfile-shim {
  height: 10rem;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.fav-shimmerbtn {
  height: 2rem;
  border-style: none;
  width: 8rem;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
}

.userProfile-shim {
  height: 2rem;
  width: 12rem;
  border-radius: 14px;
}

.profile-desc-shim {
  height: 4rem;
  width: 20rem;
  border-radius: 14px;
}

.profile-details-shimmer {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  min-height: 15.5rem;
}

.flex-grow-shimmer {
  flex-wrap: wrap;
  gap: 10px;
}


/*Right Aside shimmer*/

.RSA-shm{
  padding: 0px 10px;
}

.RSA-shm > .div-con{
  width: 19%;
}

.RSA-shm .img{
  width: 46px;
  height: 46px;
  border-radius: 50%;
}
.RSA-shm .name{
  height: 46px;
  border-radius: 4px;
  width: 40%;
}
.RSA-shm .btn{
  height: 46px;
  border-radius: 4px;
  width: 36%;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
@media screen and (min-width: 768px) {
  .bannerShimmer {
    height: 10rem;
  }

  .profile-desc-shim {
    width: 25rem;
  }

  .goal-shimmer {
    width: 238px;
  }
}

@media screen and (max-width: 768px) {
  .flex-grow-shimmer {
    flex-flow: column;
    gap: 1rem;
    align-items: center;
  }
}
